import React from "react";
import { Routes, Route } from "react-router-dom";

import Project from "../screens/project";
import Home from "../screens/home";


const Root = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}/>
      {/* <Route path="/about" element={<About/>}/>
      <Route path="/Contact" element={<Contact/>}/> */}
      {/* <Route path="/project" element={<Project/>}/> */}
      
      
    </Routes>
  );
};

export default Root;