import React from 'react'
import "./index.css"

function Heading() {
  return (
 
    <div className='w-full flex-col align-middle'>
        <div className=' text=white'>Technologies</div>

<div class="w-full md:flex flex-wrap gap-4 text-white text-center justify-center md:mt-12 mt-[25rem] ">
    
    
  <div class=" container select-players main">
    <h1 class="title">FRONTEND DEVELOPMENT</h1>
    <div class="columns">
      <button class="  players">HTML</button>
      <button class="  players">CSS</button>
      <button class="  players">Java Script</button>
      <button class="  players">React</button>
      <button class="  players">Tailwind.css</button>
    </div>
  </div>
  <div class="animated container select-players main">
    <h1 class="title">BACKEND DEVELOPMENT</h1>
    <div class="columns">
      <button class="  players">PHP</button>
      <button class="  players">PYTHON</button>
      <button class="  players">Java </button>
      <button class="  players">.NET</button>
      <button class="  players">DJANGO</button>
    </div>
  </div>
  </div>

 
 
 
</div>
  )
}

export default Heading; 

