import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaPhoneAlt,
  FaEnvelope,
  FaLocationArrow,
} from "react-icons/fa";
import image1 from "./s-blogimg-01.png"
import image2 from "./s-blogimg-02.png"

function Footer2() {
  return (
    <>
    
   
    <div className="w-full bg-[#210047]  py-[100px] px-3 md:px-0">
      <div className="container mx-auto">
        <div className="flex justify-between flex-wrap">
          {/* About Us Section Start */}
          <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%]">
            <h4 className="text-white py-2 border_orange font-bold"> About Us</h4>
            <p className="text-[#b2c0d8]">
            Aaadhishree: Your gateway to top company! We specialize in software development  to the best company for their chosen streams. Empower your educational journey with aadhishree expert advice.
            </p>
            <div className="flex gap-3 py-2">
              <div className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47] w-[36px] h-[36px] rounded-full hover:bg-red-400 py-1">
                <FaFacebookF className="text-white" />
              </div>
              <div className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47] w-[36px] h-[36px] rounded-full hover:bg-red-400 py-1">
                <FaInstagram className="text-white" />
              </div>
              <div className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47] w-[36px] h-[36px] rounded-full hover:bg-red-400 py-1">
                <FaTwitter className="text-white" />
              </div>
            </div>
          </div>

          {/* EnD About Us Section Start */}

          {/* Our Links Start*/}

          <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%]">
            <h4 className="text-white py-2 border_orange font-bold">Our Links</h4>
            <ul className="pl-0">
              <li className="py-2">
                <a href="#" className="text-[#b2c0d8] no-underline">
                  Home
                </a>
              </li>
              <li className="py-2">
                <a href="#" className="text-[#b2c0d8] no-underline">
                  About
                </a>
              </li>
              <li className="py-2">
                <a href="#" className="text-[#b2c0d8] no-underline">
                  Courses
                </a>
              </li>
              <li className="py-2">
                <a href="#" className="text-[#b2c0d8] no-underline">
                  Contact Us
                </a>
              </li>
              {/* <li className="py-2">
                <a href="#" className="text-[#b2c0d8] no-underline">
                  Blog
                </a>
              </li> */}
            </ul>
          </div>

          {/* Our Links EnD*/}

          {/* Latest Post Start */}
          <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%]">
            <h4 className="text-white py-2 border_orange font-bold">Blogs</h4>
            <div className="flex py-2 gap-2">
              <div>
                <img src={image1} alt="footer_img" />
              </div>
              <div className="ml-2">
             <p className="mb-1">
                    <a href="#" className="no-underline text-white">
                      Nothing impossble to need hard work
                    </a>
             </p>
                <span className="text-red-400">7 March, 20204</span>
              </div>
            </div>
            <div className="flex gap-2">
              <div>
                <img src={image2} alt="footer_img" />
              </div>
              <div className="ml-1">
              <p className="mb-1">
                    <a href="#" className="no-underline text-white">
                      Nothing impossble to need hard work
                    </a>
              </p>
                <span className="text-red-400">7 March, 2024</span>
              </div>
            </div>
          </div>

          {/* Latest Post EnD */}

          {/* Contact Us Start */}

          <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%]">
            <h4 className="text-white py-2 border_orange font-bold">Contact Us </h4>
            <div className="flex gap-3 py-2">
              <div className="flex items-center justify-center bg-red-400 w-[38px] h-[38px] rounded-full">
                <FaPhoneAlt className="text-white" />
              </div>
              <div className="">
                <p>
                  <a href="#" className="text-[#b2c0d8] hover:text-[#b2c0d8] no-underline">
                 9782213460
                  </a>
                </p>
                {/* <p>
                  <a href="#" className="text-[#b2c0d8] hover:text-[#b2c0d8] no-underline">
                    +1 (444) 000-8888
                  </a>   
                </p> */}
              </div>
            </div>
            <div className="flex gap-3">
              <div className="flex items-center justify-center bg-red-400 w-[38px] h-[38px] rounded-full">
                <FaEnvelope className="text-white" />
              </div>
              <div className="mt-4">
                <p>
                  <a href="#" className="text-[#b2c0d8] no-underline hover:text-[#b2c0d8]">
                    naveen@adhishreetechnology@gmail.com
                  </a>
                </p>
                {/* <p>
                  <a href="#" className="text-[#b2c0d8] no-underline hover:text-[#b2c0d8]">
                    help@example.com
                  </a>
                </p> */}
              </div>
            </div>

            <div className="flex gap-3 mt-4">
              <div className="flex items-center justify-center bg-red-400 w-[38px] h-[38px] rounded-full">
                <FaLocationArrow className="text-white" />
              </div>
              <div>
                <p className="text-[#b2c0d8]">
                   sodala,
                  <br />
                  sanganer , Jaipur(Rajasthan)
                </p>
              </div>
            </div>
          </div>

          {/* Contact Us EnD*/}
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer2;
