export const SERVICEDATA = [
  {
    id: "0",
    image: require("./images/mobile.png"),
    title: "Mobile Developement",
  },
  {
    id: "1",
    image: require("./images/web.png"),
    title: "Web Developement",
  },
  {
    id: "2",
    image: require("./images/desktop.png"),
    title: "Desktop Developement",
  },
];

export const PROJECTDATA = [
  {
    id: "0",
    image: require("./images/chance.png"),
    title: "Chance",
    description:
      "Chance is an intelligent personality-based, pseudonymous connecting and dating app that matches you with people based on personality and interests.You're more than just a photo. You have stories to tell, passions to share, and things to talk about that are more interesting than just the weather. Because you deserve what dating deserves, a real Chance!",
    image4: require("./images/chance/swipe.png"),
    images: [
      require("./images/chance/match.png"),
      require("./images/chance/quirk.png"),
      require("./images/chance/reveal.png"),
    ],
    playStoreLink:
      "https://play.google.com/store/apps/details?id=com.chanceapp.android",
  },
  {
    id: "1",
    image: require("./images/tmc.png"),
    title: "TMC",
    description:
      "The Morning Context is a media and research company that tells the most ambitious, timely, deeply researched, and well-written stories. Paid for primarily by the readers. We publish more than 200 stories every year, that cover subjects ranging from startups, technology, and e-commerce, to fintech, retail, and education.",
    image4: require("./images/tmc/latest.png"),
    images: [
      require("./images/tmc/active.png"),
      require("./images/tmc/teli.png"),
      require("./images/tmc/yest.png"),
    ],
    playStoreLink:
      "https://play.google.com/store/apps/details?id=com.app.themorningcontext",
  },
  {
    id: "2",
    image: require("./images/happy.png"),
    title: "TheHappyPlace",
    description:
      "The Happy Place is your one-stop solution whenever you want a smile on your face. Horrible boss? Irritating parents? Troubling partner? or nagging spouse? No matter what is making you feel low, we have a solution to it.",

    image4: require("./images/happy/amazing.png"),
    images: [
      require("./images/happy/bot.png"),
      require("./images/happy/low.png"),
      require("./images/happy/no.png"),
    ],
    playStoreLink: "https://apps.apple.com/in/app/the-happy-place/id1543197310",
  },
  {
    id: "3",
    image: require("./images/weconnect.png"),
    title: "WeConnect",
    description:
      "WeConnect helps companies connect with their website audience using conversational experiences combining chatbot, live messaging, video chat, booked meetings, and much more. ",
    images: [
      require("./images/weconnect/login.png"),
      require("./images/weconnect/we.png"),
      require("./images/weconnect/login.png"),
    ],
    playStoreLink:
      "https://play.google.com/store/apps/details?id=com.weconnect.android",
  },
];
