import React from "react";
import Typewriter from "typewriter-effect";
import { useMediaQuery } from "usehooks-ts";

import "./index.css";

const Details = () => {
  const winHeight = window.innerHeight;
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div id="details" style={{ height: winHeight }}>
      {isMobile ? null : <div className="ani" />}
      <div className="subDiv">
        <p className="title">ADHISHREE</p>
        <p className="title" style={{ color: "#77039e" }}>
          TECHNOLOGIES
        </p>
        <p className="subTitle">
          <Typewriter
            options={{ autoStart: true, loop: true }}
            onInit={(typewriter) => {
              typewriter
                .typeString("DEVELOPING THE FUTURE")
                .pauseFor(500)
                .deleteAll()
                .typeString("Empowering Your Online Presence")
                .pauseFor(500)
                .start();
            }}
          />
        </p>
        <button className="button">View Our Projects</button>
      </div>
    </div>
  );
};

export default Details;
