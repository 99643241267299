import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { SiBookstack } from "react-icons/si";
import { BsTrophyFill } from "react-icons/bs";
import { CiDeliveryTruck } from "react-icons/ci";

const WhyUs = () => {
  return (
    <div className="flex flex-col lg:flex-row py-12 justify-around bg-black text-white">
      <div className="w-full lg:w-[40%] px-4 lg:px-0 mb-8 lg:mb-0">
        <div className="text-[38px] lg:text-[58px] font-semibold">
          Why Choose Us
        </div>
        <div className="text-[14px] lg:text-[16px] text-[#686a6f] mt-4">
          <h3 className="font-bold">Experienced Team</h3>
          Our team of experts brings years of experience and a passion for
          technology to every project.
          <h3 className="font-bold mt-2">Customer-Centric Approach</h3> We
          prioritize your needs and work closely with you to ensure your
          complete satisfaction.
          <h3 className="font-bold mt-2">Innovative Solutions</h3> We leverage
          the latest technologies and best practices to deliver innovative and
          effective solutions.
          <h3 className="font-bold mt-2">Proven Track Record</h3> Our portfolio
          showcases successful projects across various industries, reflecting
          our commitment to excellence.
        </div>
        <div className="py-3">
          <div className="flex py-2 items-center">
            <AiFillCheckCircle className="text-[30px] text-[#002147] mr-2" />
            <div className="text-[16px]">QUICK RESPONSE</div>
          </div>
          <div className="flex items-center">
            <AiFillCheckCircle className="text-[30px] text-[#002147] mr-2" />
            <div className="text-[16px]">100% SATISFACTION</div>
          </div>
          <div className="flex py-2 items-center">
            <AiFillCheckCircle className="text-[30px] text-[#002147] mr-2" />
            <div className="text-[16px]">CREATIVE SERVICE</div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-[30%] p-5 text-white bg-[#77039e]">
        <div className="flex items-center mt-1">
          <SiBookstack className="text-[50px] mr-4" />
          <div className="relative">
            <div className="text-[40px] font-bold">
              150
              <span className="text-[20px] absolute">+</span>
            </div>
            <div className="text-[16px]">Project</div>
          </div>
        </div>
        <div className="flex items-center mt-3">
          <CiDeliveryTruck className="text-[40px] mr-4" />
          <div>
            <div className="text-[40px] font-bold">
              250
              <span className="text-[20px] absolute">+</span>
            </div>
            <div className="text-[16px]">Good Delivered</div>
          </div>
        </div>
        <div className="flex items-center mt-3">
          <BsTrophyFill className="text-[40px] mr-4" />
          <div>
            <div className="text-[40px] font-bold">
              250
              <span className="text-[20px] absolute">+</span>
            </div>
            <div className="text-[16px]">Good Client</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
