
import React, { useState } from "react";
import "./index.css";
import { TbArrowNarrowUp, TbRotateRectangle } from "react-icons/tb";
import { FcCustomerSupport, FcTodoList } from "react-icons/fc";
// import { FaWpforms } from "react-icons/fa";

function OneStopSolution() {
  const [solution, setSolution] = useState([
    {
      ico: <TbArrowNarrowUp className="text-4xl font-[900] text-white" />,
      titel: "Custom Product Development:",
      subTit: "Case Study: A retail business needed a tailored inventory management system. The IT company developed a custom solution that integrated seamlessly with their existing processes, reducing inventory discrepancies by 50% and improving stock management efficiency.",
    },
    {
      ico: <TbRotateRectangle className="text-4xl font-[900] text-white" />,
      titel: "Digital Transformation:",
      subTit: "Case Study: A traditional manufacturing company sought to modernize its operations. The IT company implemented a comprehensive digital transformation strategy, including IoT integration and data analytics, which resulted in a 30% increase in operational efficiency and reduced downtime.",
    },
    {
      ico: <FcTodoList className="text-4xl font-[900] text-white" />,
      titel: "Mobile Application Development:",
      subTit:
        "Case Study: A healthcare provider required a mobile app to improve patient engagement. The IT company created a user-friendly app featuring appointment scheduling, telemedicine, and health tracking, leading to a 40% increase ",
    },
    {
      ico: <TbRotateRectangle className="text-4xl font-[900] text-white" />,
      titel: "Cloud Solutions:",
      subTit:
        "Case Study: A financial services firm needed to migrate its operations to the cloud for better scalability and security. The IT company provided a robust cloud migration plan and ongoing support, enhancing data security and reducing operational costs by 20%.",
    },
    {
      ico: <FcCustomerSupport className="text-4xl font-[900] text-white" />,
      titel: "E-commerce Solutions:",
      subTit: "Case Study: An emerging fashion brand wanted to establish an online presence. The IT company developed a comprehensive e-commerce platform with advanced features like AI-driven product recommendations and seamless payment integration, boosting online sales by 60% within the first quarter.",
    },
    {
      ico: <FcTodoList className="text-4xl font-[900] text-white" />,
      titel: "IT Infrastructure Management:",
      subTit: "Case Study: A large educational institution faced challenges with IT infrastructure maintenance. The IT company provided end-to-end infrastructure management services, including 24/7 monitoring and support, which led to a 40% reduction in IT-related issues and improved overall system uptime.",
    },
  ]);
  return (
    <div className="bg-black text-white w-full py-[5rem]   flex flex-col items-center ">
      <div className="w-fit flex justify-center mb-8">
        <p className="text-[30px] font-bold">
          Our One Stop Solution
        </p>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-wrap gap-3  justify-between">
          {solution.map((data, i) => {
            return (
              <div className="w-full sm:w-[48%] lg:w-[45%] mb-5 px-2 ">
                <div className=" flex border-2 gap-3 p-2 
                rounded-xl
                 items-center hover:shadow-2xl py-[2em] ">
                  <div className="flex w-[60px] h-[60px] rounded-full 
                   bg-blue-400 items-center text-white justify-center">
                    {data.ico}
                  </div>
                  <div className="w-[80%] ">
                    <h3 className="bg-[#77039e] text-white pl-2 rounded-md mb-2">{data.titel}</h3>
                    <p className="">{data.subTit}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OneStopSolution;
