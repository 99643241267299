import React, { useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

function Card() {
  useEffect(() => {
    gsap.utils.toArray(".candy-card").forEach((card, i) => {
      gsap.fromTo(card, 
        {
          opacity: 0,
          y: 50
        },
        {
          opacity: 20,
          y: 10,
          scrollTrigger: {
            trigger: card,
            start: "top 80%",
            end: "bottom 20%",
            scrub: 10,
            onEnter: () => gsap.to(card, { opacity: 10, y: 0, duration: 20 }),
            onLeaveBack: () => gsap.to(card, { opacity: 0, y: 50, duration: 20 })
          }
        }
      );

      gsap.to(card, {
        opacity: 1,
        x: i % 2 === 0 ? -100 : 100,
        scrollTrigger: {
          trigger: card,
          start: "top 20%",
          end: "bottom -20%",
          scrub: 10,
          onLeave: () => gsap.to(card, { opacity: 0, x: i % 2 === 0 ? -100 : 100, duration: 1 }),
          onEnterBack: () => gsap.to(card, { opacity: 1, x: 0, duration: 1 })
        }
      });
    });
  }, []);

  return (
    <>
      <div className="candy-container">
        <div className="candy-card left">
          <h2>Web development</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, explicabo.</p>
          <img src="https://magicsolutions.co/img/sun.png" alt="" />
        </div>
        <div className="candy-card right">
          <h2>App development</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, explicabo.</p>
          <img src="https://magicsolutions.co/img/moon.png" alt="" />
        </div>
        <div className="candy-card left">
          <h2>Digital marketing</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, explicabo.</p>
          <img src="https://magicsolutions.co/img/star.png" alt="" />
        </div>
        <div className="candy-card right">
          <h2>Software development</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, explicabo.</p>
          <img src="https://magicsolutions.co/img/sphere.png" alt="" />
        </div>
        <div className="candy-card left">
          <h2>Digital marketing</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, explicabo.</p>
          <img src="https://magicsolutions.co/img/star.png" alt="" />
        </div>
        <div className="candy-card right">
          <h2>Software development</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, explicabo.</p>
          <img src="https://magicsolutions.co/img/sphere.png" alt="" />
        </div>
        
      </div>
    </>
  );
}
 
export default Card;
