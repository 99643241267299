import React, { useState } from "react";
import { CgMenu } from "react-icons/cg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

import logo from "./image/Screenshot (36)-Photoroom.png";
import "./index.css";

function Navbar({ hideNavbar = false, sectionVisible }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const isTab = useMediaQuery("(max-width: 960px)");
  const winH = window.innerHeight;

  const links = [
    {
      name: "About",
      depth: 0,
      linkaddress:"/about"
    },
    {
      name: "Service",
      depth: winH - 120,
       linkaddress:"/services"
    },
    {
      name: "Project",
      depth: (winH - 120) * 2,
       linkaddress:"/project"
    },
    {
      name: "Contact",
      depth: winH * 3,
       linkaddress:"/contact"
    },
  ];

  const onNavItemClick = (depth) => {
    window.scrollTo(0, depth);
    setIsOpen(false);
  };

  return hideNavbar && !isOpen ? (
    <div id="toggleBtn" onClick={() => setIsOpen(true)}>
      <CgMenu />
    </div>
  ) : (
    <>
      <section
        id="navbar"
        style={isOpen && !isDesktop ? { backgroundColor: "#FFF" } : {}}
      >
        <div className="subDiv">
          <Link to="/">
            <img src={logo} alt="" className="logo"></img>
          </Link>
          <div className="ul-Div">
            <div
              className="openMenu"
              onClick={() => setIsOpen(!isOpen)}
              style={isOpen && !isDesktop ? { color: "#000" } : {}}
            >
              <CgMenu />
            </div>
            <ul
              style={
                isTab === true && !isOpen
                  ? { display: "none" }
                  : { left: "0px", marginTop: -10}
              }
              className="listWrapper"
            >
              {links.map((item, index) => (
                <li className="list" key={index}>
                  <Link
                  to={item.linkaddress}
                    onClick={() => onNavItemClick(item.depth)}
                    style={sectionVisible == index ? { color: "#fc71ae" } : {}}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Navbar;
