import React, { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import "./index.css"

import Navbar from "../../components/navbar";
import Details from "./details";
import Services from "./services";
import Projects from "./projects";
import ContactUs from "./contactUs";
import "./index.css";
import Card from "../../components/cards";
import WhyUs from "./whyUs";
import OneStopSolution from "./oneStopSolution";
import Card2 from "../../components/card2";
import Heading from "../../components/heading";
import Footer2 from "../../components/Footer/Footer";

const Home = () => {
  const [hideNavbar, setHideNavbar] = useState(false);
  const [sectionVisible, setSectionVisible] = useState(0);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const winH = window.innerHeight;

  const handleScroll = () => {
    const position = window.scrollY;
    setSectionVisible(Math.round(position / winH));
    if (position == 0) setHideNavbar(false);
    else setHideNavbar(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div onScroll={handleScroll} className="bg">
      <Navbar
        hideNavbar={hideNavbar && isMobile}
        sectionVisible={sectionVisible}
      />
      <Details />
      <Services />
      <Heading/>
      {/* <Projects /> */}
      {/* <Card2/> */}
      <OneStopSolution/>
      <WhyUs/>
      <ContactUs />
      <Footer2/>
    </div>
  );
};

export default Home;
