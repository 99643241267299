import React from 'react'
import "./card2.css"
import Heading from '../heading'

function Card2() {
  return (
 <div className='w-full bg-black flex justify-center'>


<div className="card ">
  <p class="heading">
    Frontend Development
  </p>
  <p>
   HTML
  </p>
  <p>CSS
</p>
<p>
   Tailwind
  </p>
  <p>
   .NET
  </p>
  <p>
   React.js
  </p>
  <p>
   Node.js
  </p>



</div>
<div className="card ">
  <p class="heading">
    Frontend Development
  </p>
  <p>
   HTML
  </p>
  <p>CSS
</p>
<p>
   Tailwind
  </p>
  <p>
   .NET
  </p>
  <p>
   React.js
  </p>
  <p>
   Node.js
  </p>



</div>
<div className="card ">
  <p class="heading">
    Frontend Development
  </p>
  <p>
   HTML
  </p>
  <p>CSS
</p>
<p>
   Tailwind
  </p>
  <p>
   .NET
  </p>
  <p>
   React.js
  </p>
  <p>
   Node.js
  </p>



</div>
</div>
  )
}

export default Card2