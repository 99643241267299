import './App.css';
// import Footer from './components/common/footer/page';


import { BrowserRouter } from 'react-router-dom';
import Root from './routes';
import Navbar from './components/navbar';


function App() {
  return (
    <div className="">
      <BrowserRouter>
      
      <Navbar/>
      <Root/>
      {/* <Footer/> */}
      </BrowserRouter>
     
    </div>
  );
}

export default App;