import React, { useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { sendEmailHelper } from "../../../helpers";
import "./index.css";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [details, setDetails] = useState("");
  const winheight = window.innerHeight;
  const isMobile = useMediaQuery("(max-width: 768px)");

  const sendEmail = (e) => {
    e.preventDefault();
    if (!name || !phone || !Number(phone) || !email || !details)
      return alert("Fill valid details");
    var data = {
      from_name: "random name",
      from_email: "randomemail@gmail.com",
      name,
      phone,
      email,
      details,
    };
    sendEmailHelper("template_bzhdzhj", data);
  };

  return (
    <div
      id="contact"
      style={{ height: isMobile ? winheight : winheight - 120 }}
    >
      <div className="wrapper">
        <div className="infoWrapper">
          <p className="title">Contact Request</p>
          <div className="inputWrapper">
            <input
              className="request"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="request"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="request"
              type="text"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <textarea
              className="request"
              type="text"
              placeholder="Project Details"
              aria-multiline={true}
              style={{ height: 100, paddingTop: 10 }}
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
            <div className="buttonWrapper">
              <button className="button" onClick={sendEmail}>
                Send Request
              </button>
            </div>
          </div>
        </div>
        <div className="infoWrapper separator">
          <p className="title">Contact Info</p>
          <p className="txt">
            Need help? Feel free to contact us and we'll respond as soon as
            possible.
          </p>
          <p className="txt2">bhawani@gmail.in</p>
          <p className="txt2">+91 7791996354</p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
