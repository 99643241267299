import emailjs from "@emailjs/browser";

export const sendEmailHelper = (templateId, data) => {
  emailjs.send("service_ahfyvba", templateId, data, "k7Mz7Z0VIw2q4_VlQ").then(
    (result) => {
      alert("Request raised!");
    },
    (error) => {
      alert("Error!");
    }
  );
};
