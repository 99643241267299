import React from "react";
import { Link } from "react-router-dom";

import { SERVICEDATA } from "../../../constants";
import "./index.css";
import Card from "../../../components/cards";

const Services = () => {
  const winHeight = window.innerHeight;
  return (
    <div id="services" style={{ height: winHeight,  }}>
      <div>
        <hi className="title">OUR SERVICES</hi>
        <p className="subTitle">
          Mercury seamlessly integrates into your business workflow whether you
          are looking to create a new application, or need help with development
          and/or QA of your existing solution. We provide teams capable of
          increasing the velocity of your development and extending your
          technical capabilities.
        </p>
     
      </div>
     
      {/* <div className="wrapper">
        {SERVICEDATA.map((item) => (
          <div className="items">
          
            <img src={item.image} alt="" className="img" />
           
            <p className="detailText">{item.title}</p>
          </div>
        ))}
      </div> */}
      <Card/>
    </div>
  );
};

export default Services;
